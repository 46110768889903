<template>
  <div v-loading="loading" class="notice-notifications">
    <div class="notice-notice-title" @click="openSysPop(dataList, 'all')">
      <div class="title-left">
        <div class="title-left-icon">
          <img
            class="left-icon"
            src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/b135c201cb458e8eb0afd4a20a893eec.png"
          />
        </div>
        <span class="title-left-letter">
          {{ $t('systemNotice.systemNotice.666307-0') }}
        </span>
      </div>
      <div class="title-right">
        <span class="title-right-letter" v-if="dataListLength">
          {{ dataListLength }}{{ dataListLength === 99 ? '+' : null }}
        </span>
        <div class="title-right-icon">
          <img
            class="right-icon"
            src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/10809eb5d0a4e685ce390b4f6d0cd796.png"
          />
        </div>
      </div>
    </div>
    <div class="notice-notice-list" v-if="dataList.length">
      <NoticeItem
        listType="notice"
        @on-show-detail="openSysPop"
        @refresh="getData"
        :dataList="dataList"
        :itemType="2"
      />
    </div>
    <div class="noData" v-else>
      <div>
        <i class="iconfont iconzanwutongzhi"></i>
      </div>
      <p>{{ $t('systemNotice.systemNotice.666307-1') }}</p>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { defineEmits, onMounted, ref, defineProps, watch } from 'vue';
import NoticeItem from '@/views/todo/components/rightCont/alertNotify/components/alertItem.vue';
import { dataInterface } from '@/apis/data';

const emits = defineEmits(['switchSysPop']);

const dataList = ref([]);
const dataListLength = ref(0);
const loading = ref(false);

const props = defineProps({
  isUpdate: {
    type: Boolean,
    default: false,
  },
});

watch(
  () => props.isUpdate,
  () => {
    getData();
  }
);

const openSysPop = (data, type) => {
  emits('switchSysPop', data, type);
};

const getData = () => {
  loading.value = true;
  dataListLength.value = 0;
  let count = 0;
  let list = [];
  dataInterface({}, 'api/graph/1287').then((res) => {
    loading.value = false;
    if (res.data.code === 200) {
      res.data.data.forEach((element) => {
        if (+element.is_read !== 1 || element.is_to_top) {
          list.push(element);
        }
        if (+element.is_read !== 1) {
          count++;
        }
      });
      dataList.value = list;
      dataListLength.value = count;
      if (dataListLength.value > 99) {
        dataListLength.value = 99;
      }
    }
  });
};

onMounted(() => {
  getData();
});
</script>

<style lang="less" scoped>
.notice-notifications {
  display: flex;
  // margin: 16px 0;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: var(--Radio-12, 12px);
  background: var(--overall-surface-default, #fff);
  overflow: hidden;
  position: relative;
  z-index: 1;
  .notice-notice-title {
    display: flex;
    justify-content: space-between;
    height: 56px;
    padding: 8px var(--spacing-10, 20px);
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-bottom: 1px solid var(--border-on-surface-weak, #f0f1f4);
    box-sizing: border-box;

    .title-right {
      display: flex;
      align-items: center;

      .title-right-letter {
        display: flex;
        height: 18px;
        min-width: 18px;
        padding: 0px 3px 0.5px 3px;
        justify-content: center;
        box-sizing: border-box;
        align-items: center;
        border-radius: var(--Radio-6, 6px);
        background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0.2) 0%,
            rgba(255, 255, 255, 0) 100%
          ),
          var(--semantic-error-background-variant, #fc5558);
        color: var(--semantic-error-on-background-variant, #fff4f4);
        // text-align: center;
        font-family: 'MiSans VF';
        font-size: 12px;
        font-style: normal;
        font-weight: bold;
        line-height: 12px;
        /* 100% */
        box-shadow: 0px 0.5px 3px 0px rgba(252, 85, 88, 0.12),
          0px 1px 2px 0px rgba(252, 85, 88, 0.12),
          0px 0.5px 1px 0px rgba(252, 85, 88, 0.24);
      }
    }

    .title-right-icon {
      display: flex;
      width: var(--button-list-field-small, 24px);
      height: var(--button-list-field-small, 24px);
      justify-content: flex-end;
      align-items: center;
      border-radius: var(--Radio-6, 6px);

      .right-icon {
        width: var(--Radio-16, 16px);
        height: var(--Radio-16, 16px);
        // transform: rotate(-90deg);
        flex-shrink: 0;
      }
    }

    .title-left {
      display: flex;
      align-items: center;
      gap: 4px;

      .title-left-letter {
        color: var(--text-on-surface-primary, #181b22);
        font-family: 'MiSans VF';
        font-size: 16px;
        font-style: normal;
        font-weight: 450;
        line-height: 24px;
        /* 150% */
        flex: 1 0 0;
      }

      .title-left-icon {
        display: flex;
        width: 36px;
        height: 36px;
        justify-content: center;
        align-items: center;

        .left-icon {
          display: flex;
          width: 23px;
          height: 23px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
        }
      }
    }
  }

  .notice-notice-list {
    display: flex;
    padding: 4px;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
    // margin-right: 5px;
  }

  .noData {
    width: 100%;
    height: 198px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #a4acbd;
    i {
      text-align: center;
      font-family: 'SF Pro';
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 48px; /* 184.615% */
    }
    p {
      font-family: 'MiSans VF';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
    }
  }
}
</style>
