<!--
 * @Description: 小日历组件
 * @Author: luocheng
 * @Date: 2021-07-15 15:54:26
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @LastEditTime: 2024-08-30 16:28:30
-->
<template>
  <div class="calendar-component">
    <article class="main">
      <div class="top-handle">
        <div>
          {{
            i18n.locale === 'en'
              ? type !== 'month'
                ? monthList[+dateValue.split('-')[1] - 1] +
                  dateValue.split('-')[0]
                : dateValue
                    .slice(0, 5)
                    .replace('-', $t('calendar.CalendarComponent.189440-0'))
              : type !== 'month'
              ? dateValue.replace(
                  '-',
                  $t('calendar.CalendarComponent.189440-0')
                ) + $t('calendar.CalendarComponent.189440-1')
              : dateValue
                  .slice(0, 5)
                  .replace('-', $t('calendar.CalendarComponent.189440-0'))
          }}
        </div>
        <div>
          <i
            @click="onChangeMonth('prev')"
            class="iconfont iconxiangzuojiantou"
          ></i>
          <template v-if="type !== 'month'">
            <span
              @click="onBackDate()"
              v-if="type !== 'week'"
              @mouseover="hoveCurrentDate = true"
              @mouseout="hoveCurrentDate = false"
            >
              {{
                hoveCurrentDate
                  ? $t('calendar.CalendarComponent.189440-2')
                  : currentDate === todayDate
                  ? $t('calendar.CalendarComponent.189440-3')
                  : $t('calendar.CalendarComponent.189440-4', [currentNum])
              }}
            </span>
            <span
              @click="onBackDate()"
              v-else
              @mouseover="hoveCurrentDate = true"
              @mouseout="hoveCurrentDate = false"
            >
              {{
                hoveCurrentDate
                  ? $t('calendar.CalendarComponent.189440-5')
                  : $t('calendar.CalendarComponent.189440-6', [getWeekOfMonth])
              }}
            </span>
          </template>
          <template v-else>
            <span
              @click="onBackDate()"
              @mouseover="hoveCurrentDate = true"
              @mouseout="hoveCurrentDate = false"
            >
              {{
                hoveCurrentDate
                  ? $t('calendar.CalendarComponent.189440-7')
                  : monthList[+dateValue.slice(5, 6) - 1]
              }}
            </span>
          </template>
          <i
            @click="onChangeMonth('next')"
            class="iconfont iconxiangyoujiantou"
          ></i>
        </div>
      </div>
      <div class="bottom-cont">
        <header class="header">
          <section
            class="sign"
            v-for="(sign, signIndex) in signList"
            :key="signIndex"
          >
            {{ sign }}
          </section>
        </header>
        <article class="content" :class="{ 'week-content': type === 'week' }">
          <!-- 日期选择器 -->
          <template v-if="type === 'day' || type === 'month'">
            <section
              class="item"
              v-for="item in dateList"
              :key="item.date"
              :class="{
                disable: item.disabled,
                'not-show': item.disabled && !fill,
                current: type === 'day' ? currentDate === item.date : false,
                'is-today': showToday && item.isToday,
                'is-type-date': type === 'day',
              }"
              :style="{
                marginBottom: type === 'week' ? '0px' : '8px',
              }"
              @click="onGetDay(item)"
            >
              {{
                showToday && item.isToday
                  ? $t('calendar.CalendarComponent.189440-9')
                  : item.label
              }}
              <span
                class="point"
                :style="{
                  background:
                    currentDate === item.date
                      ? '#fff'
                      : STATUS_LIST.find((val) => val.type === +todoType)
                          ?.point_color,
                }"
                v-if="markOption.includes(item.date)"
              ></span>
            </section>
          </template>
          <!-- 周选择器 -->
          <template v-else>
            <section
              class="week-item"
              v-for="weekItem in weekList"
              :key="weekItem.index"
              @click="onGetDay(weekItem.days[0], weekItem.index)"
              :class="{
                'current-week': currentWeek === weekItem.index,
              }"
            >
              <section
                class="item"
                v-for="item in weekItem.days"
                :key="item.date"
                :class="{
                  disable: item.disabled,
                  // 'current': currentDate === item.date,
                  'is-today': showToday && item.isToday,
                }"
              >
                {{
                  showToday && item.isToday
                    ? $t('calendar.CalendarComponent.189440-9')
                    : item.label
                }}
                <!-- 点 -->
                <span
                  class="point"
                  :style="{
                    background: STATUS_LIST.find(
                      (val) => val.type === +todoType
                    )?.point_color,
                  }"
                  v-if="markOption.includes(item.date)"
                ></span>
              </section>
            </section>
          </template>
        </article>
      </div>

      <div v-if="type !== 'day'" class="total-desc">
        <span
          class="dot"
          :style="{
            background: STATUS_LIST.find((val) => val.type === +todoType)
              ?.point_color,
          }"
        ></span>
        <p>
          {{ typeStatusLang }}
          <span>({{ dataLen }})</span>
        </p>
      </div>
      <div v-else class="total-desc">
        <span
          class="dot"
          :style="{
            background: STATUS_LIST.find((val) => val.type === +todoType)
              ?.point_color,
          }"
        ></span>
        <p>
          {{ typeStatusLang }}<span>({{ dataLen }})</span>
        </p>
      </div>
    </article>
  </div>
</template>

<script setup>
/* eslint-disable */
import { onMounted, ref, defineEmits, defineProps, watch, computed } from 'vue';
import { parseTime } from '@/utils/tools';
import { STATUS_LIST } from '@/views/todo/constant.js';
const emits = defineEmits(['update-date']);
import { dataInterface } from '@/apis/data/index';
import i18n from '@/locale/index';
const lang = localStorage.getItem('preferred_lang');
const props = defineProps({
  // 类型 date 正常日期 week 周选择器
  type: {
    type: String,
    default: 'date',
    required: false,
  },
  // 头部标志位
  signList: {
    type: Array,
    default: () => [
      i18n.t('calendar.CalendarComponent.189440-13'),
      i18n.t('calendar.CalendarComponent.189440-14'),
      i18n.t('calendar.CalendarComponent.189440-15'),
      i18n.t('calendar.CalendarComponent.189440-16'),
      i18n.t('calendar.CalendarComponent.189440-17'),
      i18n.t('calendar.CalendarComponent.189440-18'),
      i18n.t('calendar.CalendarComponent.189440-19'),
    ],
    required: false,
  },
  // 是否显示不属于本月的月份进行补齐(暂时无此需求，容后补充)
  fill: {
    type: Boolean,
    default: true,
    required: false,
  },
  // 是否显示今天标记
  showToday: {
    type: Boolean,
    default: true,
    required: false,
  },
  currentDate: {
    type: String,
    default: '',
    required: true,
  },
  todoType: {
    type: [String, Number],
    default: '1',
  },
  dataLen: {
    type: [String, Number],
    default: 0,
  },
});

const markOption = ref([]);

const dateValue = ref('');
// 日期列表
const dateList = ref([]);
// 大月 31天
const largeMonths = ref([1, 3, 5, 7, 8, 10, 12]);
const smallMonths = ref([4, 6, 9, 11]);
// 周列表二维数组
const weekList = ref([]);
// 当前所选周
const currentWeek = ref(-1);
//当前号数
const currentNum = ref('');
const todayDate = ref('');
//移入效果的开关
const hoveCurrentDate = ref(false);
const getYearMonth = (dateString) => {
  // 将日期字符串分割成数组，使用 "-" 作为分隔符
  const parts = dateString.split('-');
  // 取出年份和月份，月份需要补零以确保是两位数
  const year = parts[0];
  const month = ('0' + parts[1]).slice(-2);
  // 将年份和月份拼接成新的字符串
  return `${year}-${month}`;
};

const getCurrentMarker = () => {
  dataInterface(
    {
      is_page: 0,
      month: getYearMonth(dateValue.value),
      todo_type: props.todoType,
    },
    '/api/home/todo/list',
    'GET'
  ).then((res) => {
    if (res.data.code === 200) {
      markOption.value = res.data.data.dates.map((item) => {
        return item.replaceAll('-0', '-');
      });
    }
  });
};
watch(
  () => props.todoType,
  () => {
    markOption.value = [];
    getCurrentMarker();
  }
);
//回到当前月及周
const onBackDate = () => {
  currentWeek.value = -1;
  const date = parseTime(new Date(), '{y}-{m}-{d}');
  const dateValueArr = date.split('-');
  dateValue.value = `${dateValueArr[0]}-${dateValueArr[1]}`;
  if (props.type !== 'month') {
    currentNum.value = dateValueArr[2];
  }
  // emits('update-date', date);
  onGetDay({
    date: date,
    day: props.type !== 'month' ? dateValueArr[2] : '',
  });
  setCalendar(date);
};

// 获取当前是本年第几周
const getWeekOfMonth = computed(() => {
  const date = new Date(props.currentDate);
  const startOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - startOfYear) / 86400000 + 1; // 一天的毫秒数
  return Math.ceil(
    (pastDaysOfYear -
      (startOfYear.getDay() === 1 ? 0 : 7 - startOfYear.getDay())) /
      7
  );
});

/**
 * @desc: 设置新的日历
 * @param {*}
 * @return {*}
 */
const setCalendar = (date = null) => {
  dateList.value.length = 0;
  if (
    typeof dateValue.value !== 'string' ||
    !dateValue.value?.split('-').length
  )
    return;
  const dateArr = dateValue.value.split('-');
  if (isNaN(+dateArr[0]) || isNaN(+dateArr[1])) return;
  const year = +dateArr[0];
  const month = +dateArr[1];
  // 月的情况较特殊
  // const len = 7; // 一周有几天
  const startDay = `${year}-${month}-01 00:00:00`; // 开始日
  const dayCount = getDayCount(year, month); // 一个月有多少天
  const startWeekDay =
    new Date(startDay).getDay() === 0 ? 7 : new Date(startDay).getDay(); // 开始星期几大于0表示需要前补几天
  // 每个月最多的情况会出现会出现6周的情况 即月历中需要显示42天
  const total = 42;
  // 获取上一月的数据预填充
  if (startWeekDay > 0) {
    const prevArr = getPrevs(year, month, startWeekDay - 1);
    dateList.value = dateList.value.concat(prevArr);
  }
  // 当前月数据
  for (let i = 1; i <= dayCount; i++) {
    dateList.value.push({
      date: `${year}-${month}-${i}`,
      label: i, // 日历中显示的日期
      disabled: false, // 是否可以操作 上月下月均不可操作
      year: year,
      month: month,
      day: i,
      weekDay: new Date(`${year}-${month}-${i}`).getDay(), // 周几
      timestamp: new Date(`${year}-${month}-${i} 00:00:00`).getTime(),
      isToday: props.showToday
        ? todayDate.value === `${year}-${month}-${i}`
        : false,
    });
  }
  // 获取后一个月数据并进行填充
  if (total - dateList.value.length > 0) {
    const nextArr = getNexts(year, month, total - dateList.value.length);
    dateList.value = dateList.value.concat(nextArr);
  }
  // 周
  if (props.type === 'week') {
    weekList.value = [];
    let weekItem = [];
    currentWeek.value = -1;
    let index = 1;
    for (let i = 0; i <= dateList.value.length; i++) {
      if (i % 7 === 0 && i !== 0) {
        index++;
        weekList.value.push({
          index,
          days: weekItem,
          startDay: weekItem[0],
        });
        weekItem = [];
      }
      // 设置当前周
      let weekday = props.currentDate;
      if (date) {
        weekday = date;
      }
      if (i < dateList.value.length && dateList.value[i].date === weekday) {
        currentWeek.value = index + 1;
      }
      weekItem.push(dateList.value[i]);
    }
  }
  getCurrentMarker();
};
/**
 * @desc: 获取前一个月数据
 * @param {*} year 当前年份) number
 * @param {*} month 当前月份 number
 * @param {*} count 需要补充几天 number
 * @return {*} 上月数据数组
 */
const getPrevs = (year, month, count) => {
  const prevYear = month === 1 ? year - 1 : year;
  const prevMonth = month === 1 ? 12 : month - 1;
  const days = getDayCount(prevYear, prevMonth);
  const result = [];
  for (let i = days - count + 1; i <= days; i++) {
    result.push({
      date: `${prevYear}-${prevMonth}-${i}`,
      label: i, // 日历中显示的日期
      disabled: true, // 是否可以操作 上月下月均不可操作
      year: prevYear,
      month: prevMonth,
      day: i,
      weekDay: new Date(`${prevYear}-${prevMonth}-${i}`).getDay(), // 周几
      timestamp: new Date(`${year}-${month}-${i} 00:00:00`).getTime(),
      isToday: false,
    });
  }
  return result;
};
/**
 * @desc: 获取下一个月数据
 * @param {*} year 当前年份) number
 * @param {*} month 当前月份 number
 * @param {*} count 需要追加几天 number
 * @return {*} 下月数据数组
 */
const getNexts = (year, month, count) => {
  const nextYear = month === 12 ? year + 1 : year;
  const nextMonth = month === 12 ? 1 : month + 1;
  // const days = getDayCount(nextYear, nextMonth);
  const result = [];
  for (let i = 1; i <= count; i++) {
    result.push({
      date: `${nextYear}-${nextMonth}-${i}`,
      label: i, // 日历中显示的日期
      disabled: true, // 是否可以操作 上月下月均不可操作
      year: nextYear,
      month: nextMonth,
      day: i,
      weekDay: new Date(`${nextYear}-${nextMonth}-${i}`).getDay(), // 周几
      timestamp: new Date(`${year}-${month}-${i}`).getTime(),
      isToday: false,
    });
  }
  return result;
};
/**
 * @desc: 判断当前为大月小月，或者2月闰月 返回当月天数
 * @param {*} year 当前年 number
 * @param {*} month 当前月 number
 * @return {*}
 */
const getDayCount = (year, month) => {
  if (month === 2) {
    // 2月需要判断平年 闰年
    if (isLeapYear(year)) return 29;
    return 28;
  }
  if (largeMonths.value.includes(month)) return 31;
  if (smallMonths.value.includes(month)) return 30;
};
/**
 * @desc: 判断是够为闰年 闰年能被4整除且不能被100整除，或能被400整除
 * @param {*} year 年份 number
 * @return {*}
 */
const isLeapYear = (year) => {
  if (isNaN(year)) return false;
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};
/**
 * @desc: 选择日期
 * @param {*} item 当前所选日期/当前所选周
 * @param {*} currentWeek 当前所选周（仅周类型）
 * @return {*}
 */
const onGetDay = (item, week = -1) => {
  if (props.type === 'week') {
    currentWeek.value = week;
    emits('update-date', item.date);
  }
  if (props.type === 'day' && item.disabled) return;
  if (props.type === 'month') {
    currentNum.value = '';
  } else {
    currentNum.value = item.day;
    emits('update-date', item.date);
  }
};
/**
 * @desc: 切换月
 * @param {*}
 * @return {*}
 */
const onChangeMonth = (type) => {
  // 格式化日期为YYYY-M格式
  function formatDate(date) {
    let y = date.getFullYear();
    let m = date.getMonth() + 1; // getMonth() 返回的月份是从0开始的，所以需要+1
    return `${y}-${m}`;
  }
  let dateValueArr = dateValue.value.split('-');
  let date = new Date(dateValueArr[0], dateValueArr[1] - 1, 1);
  let str = '';
  if (type === 'prev') {
    // 上个月
    str = new Date(date.getTime());
    str.setMonth(str.getMonth() - 1);
  } else if (type === 'next') {
    // 上个月
    str = new Date(date.getTime());
    str.setMonth(str.getMonth() + 1);
  }
  const chooseMonth = formatDate(str);
  getDate(chooseMonth);
  if (props.type === 'month') {
    emits('update-date', ` ${chooseMonth}-1`);
  }
};

/**
 * @desc: 获取当前所选月份
 * @param {*} date 当前年月 string
 * @return {*}
 */
const getDate = (date) => {
  if (date === dateValue.value) return;
  dateValue.value = date;
  setCalendar();
};

/**
 * @desc: 监听日周月切换
 * @return {*}
 */
watch(
  () => props.type,
  () => {
    currentWeek.value = -1;
    if (props.type === 'month') {
      currentNum.value = '';
    }
    setCalendar();
  }
);

onMounted(() => {
  todayDate.value = parseTime(new Date(), '{y}-{m}-{d}');
  if (props.showToday) {
    onGetDay({
      date: props.currentDate,
    });
  }
  const dateValueArr = props.currentDate.split('-');
  dateValue.value = `${dateValueArr[0]}-${dateValueArr[1]}`;
  if (props.type !== 'month') {
    currentNum.value = dateValueArr[2];
  }
  setCalendar();
});
const monthList = computed(() => {
  return [
    'Jan.',
    'Feb.',
    'Mar.',
    'Apr.',
    'May.',
    'Jun.',
    'Jul.',
    'Aug.',
    'Sept.',
    'Oct.',
    'Nov.',
    'Dec.',
  ];
});
const typeStatusLang = computed(() => {
  if (i18n.locale === 'zh') {
    if (props.type !== 'day') {
      return (
        i18n.t('calendar.CalendarComponent.189440-10') +
        (props.type === 'week'
          ? i18n.t('calendar.CalendarComponent.189440-11')
          : i18n.t('calendar.CalendarComponent.189440-1')) +
        STATUS_LIST.value.find((val) => val.type === +props.todoType).name
      );
    } else {
      return (
        i18n.t('calendar.CalendarComponent.189440-12') +
        STATUS_LIST.value.find((val) => val.type === +props.todoType).name
      );
    }
  } else {
    let textType = '';
    switch (
      STATUS_LIST.value.find((val) => val.type === +props.todoType).status
    ) {
      case 'pending':
        if (props.type === 'day') {
          textType = 'To-Dos for Today';
        } else if (props.type === 'week') {
          textType = 'To-Dos for This Week';
        } else {
          textType = 'To-Dos for This Month';
        }
        break;
      case 'initiatedByMe':
        if (props.type === 'day') {
          textType = "Today's Initiatives by Me";
        } else if (props.type === 'week') {
          textType = 'Initiatives by Me This Week';
        } else {
          textType = 'Initiatives by Me This Month';
        }
        break;
      case 'CCToMeTotal':
        if (props.type === 'day') {
          textType = "Today's CCs to Me ";
        } else if (props.type === 'week') {
          textType = 'CCs to Me This Week';
        } else {
          textType = 'CCs to Me This Month';
        }
        break;
      case 'processedHandled':
        if (props.type === 'day') {
          textType = "Today's Completed Tasks";
        } else if (props.type === 'week') {
          textType = 'Completed Tasks for This Week';
        } else {
          textType = 'Completed Tasks for This Month';
        }
        break;
    }
    return textType;
  }
});
</script>

<style lang="less" scoped>
@boxWidth: 256px;
@itemHeight: 20px;
@itemWidth: 32px;
@iotHeight: 4px;
.calendar-component {
  height: auto;
  width: @boxWidth;
  box-sizing: border-box;
  position: relative;
  left: 1px;
  user-select: none;
  .main {
    box-sizing: border-box;
    .top-handle {
      padding: 0 16px;
      background: #f2f5fa;
      height: 37px;
      line-height: 37px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      span {
        color: #707786;
        display: inline-block;
        width: fit-content;
        text-align: center;
      }
      i {
        color: #707786;
      }
    }
    .bottom-cont {
      padding: 12px 8px;
    }
    .total-desc {
      height: 48px;
      padding: 0 0 0 32px;
      line-height: 48px;
      position: relative;
      color: #424751;
      font-family: 'MiSans VF';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      .dot {
        display: inline-block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        top: 22px;
        left: 16px;
      }
      p {
        white-space: nowrap;
        span {
          color: #707786;
          text-align: center;
          font-family: 'MiSans VF';
          font-size: 13px;
          font-style: normal;
          font-weight: 330;
          line-height: 20px;
          letter-spacing: 2px;
          margin-left: 2px;
        }
      }
    }
    .header {
      display: flex;
      height: @itemHeight;
      margin-bottom: 8px;
      .sign {
        width: @itemWidth;
        text-align: center;
        line-height: @itemHeight;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #202126;
        line-height: 20px;
        margin-right: 2px;
        cursor: pointer;
        &:nth-of-type(7n -1) {
          color: #8a8f99;
        }
        &:nth-of-type(7n) {
          color: #8a8f99;
        }
      }
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      padding: 2px 0;
      width: 100%;
      .item {
        position: relative;
        height: 32px;
        width: @itemWidth;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #202126;
        line-height: 28px;
        cursor: pointer;
        border-radius: 6px;
        transform: all 0.1s;
        margin-right: 2px;
        &:nth-of-type(7n) {
          color: #8a8f99;
        }
        &:nth-of-type(7n - 1) {
          color: #8a8f99;
        }
        &.not-show {
          opacity: 0;
          &:hover {
            background: transparent;
            color: transparent;
          }
        }
        &.current {
          background: var(--themeColor);
          color: #fff;
        }
        &.is-today {
          font-weight: bold;
        }
        // 点
        .point {
          display: inline-block;
          height: 4px;
          width: 4px;
          position: absolute;
          bottom: 4px;
          left: calc(50% - 2px);
          margin: auto;
          border-radius: 50%;
        }
      }
      .is-type-date {
        &.disable {
          color: #8a8f99;
          &:hover {
            background: #f2f3f5;
          }
        }
        &:hover {
          background: var(--themeColor);
          color: #fff;
        }
      }
      &.week-content {
        display: block;
      }
      // 周
      .week-item {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        height: @itemHeight + @iotHeight;
        box-sizing: border-box;
        margin-bottom: 8px;
        height: 32px;
        &:hover {
          background: #f4f6f9;
          border-radius: 8px;
        }
        &.current-week {
          border-radius: 8px;
          background: #f4f6f9;
        }
        .item {
          padding-top: 0;
          height: 32px;
          &:hover {
            background: transparent;
            color: #202126;
          }
          &.f {
            color: var(--themeColor);
            &:hover {
              color: var(--themeColor);
            }
          }
          &.disable {
            &:hover {
              color: #8a8f99;
            }
          }
          &:nth-of-type(7n) {
            &:hover {
              color: #8a8f99;
            }
          }
          &:nth-of-type(7n - 1) {
            &:hover {
              color: #8a8f99;
            }
          }
        }
      }
    }
  }
}
</style>
