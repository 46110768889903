<template>
  <div v-loading="loading" class="notify-pop-left-list" ref="scrollList">
    <div class="notify-main-list" v-if="listData.length && !loading">
      <ul>
        <li
          v-for="(item, index) in listData.slice(
            (page - 1) * 20,
            page === -1 ? listData.length : page * 20
          )"
          :key="index"
          @click="pushDetail(item, index)"
          :class="{ li: item.id === keyId }"
          @mouseenter="toLiScroll(item.id)"
          @mouseleave="inOut"
        >
          <div class="item-type">
            <img
              v-if="item.warn_level === '紫色'"
              class="item-icon"
              src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/7e139645454327e9d251da0bffaed15d.png"
            />
            <img
              v-else-if="item.warn_level === '蓝色'"
              class="item-icon"
              src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/deb6e37a806a22faa9166f29f19f2697.png"
            />
            <img
              v-else-if="item.warn_level === '红色'"
              class="item-icon"
              src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/9caf1f3f68b00f0620fc94e03a02b003.png"
            />
            <img
              v-else-if="item.warn_level === '橙色'"
              class="item-icon"
              src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/68bca27decc54937810fe2e0ec0ea273.png"
            />
            <img
              v-else-if="item.warn_level === '黄色'"
              class="item-icon"
              src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/4145d0ef96331ab4b8a6deb337500146.png"
            />
            <img
              v-else
              class="item-icon"
              src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/f241cfe6331191e0f515fb536bb824ee.png"
            />
          </div>
          <svg
            v-if="+item.is_read === 0"
            style="position: absolute; left: 45px"
            xmlns="http://www.w3.org/2000/svg"
            width="8"
            height="8"
            viewBox="0 0 8 8"
            fill="none"
          >
            <circle cx="4" cy="4" r="3.5" fill="#FC5558" />
            <circle
              cx="4"
              cy="4"
              r="3.5"
              fill="url(#paint0_linear_1404_14343)"
              fill-opacity="0.2"
            />
            <circle cx="4" cy="4" r="3.5" stroke="white" />
            <defs>
              <linearGradient
                id="paint0_linear_1404_14343"
                x1="4"
                y1="1"
                x2="4"
                y2="7"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
          <div class="item-main">
            <div class="item-main-title">
              <div
                class="title"
                :class="{ paddingTitle: item.warn_content.charAt(0) !== '【' }"
              >
                <marquee v-if="idCurrent === item.id">{{
                  item.warn_content
                }}</marquee>
                <div v-else>{{ item.warn_content }}</div>
              </div>
            </div>
            <div class="item-main-other">
              <div class="item-main-other-content">
                {{ $t('components.popContentList.202539-5')
                }}{{ item.current_name }}
              </div>
              <div class="item-main-other-time">
                {{ parseTime(item.created_at, '{m}-{d} {h}:{i}') }}

                <!-- {{ Date.parse(item.created_at) }} -->
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div
      v-else-if="!listData.length && !props.loading"
      style="text-align: center; width: 100%; margin-top: 50px"
    >
      {{ $t('components.popContentList.202539-6') }}
    </div>
    <pagination
      layout="prev, pager, next"
      :total="listData.length"
      small
      v-if="listLength"
      @current-change="changePage"
      :page-size="20"
      :current-page="page"
    >
    </pagination>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, defineEmits, defineProps, onMounted, watch, nextTick } from 'vue';
import { dataInterface } from '@/apis/data';
import marquee from './rightCont/marquee.vue';
import { Message } from 'element-ui';
import { Pagination } from 'element-ui';
import { parseTime } from '@/utils/util';
import i18n from '@/locale/index';
const idCurrent = ref(-1);
const listData = ref([]);
const searchList = ref([]);
const noReadList = ref(0);
const loading = ref(false);
const keyId = ref(-1);
const scrollList = ref();
const scrollTopValue = ref(0);
const page = ref(1);
const listLength = ref(0);
const changePage = (p) => {
  page.value = p;
};

const toLiScroll = (id) => {
  idCurrent.value = id;
};
const inOut = () => {
  idCurrent.value = -1;
};

const getData = () => {
  loading.value = true;
  const list1 = [];
  const list2 = [];
  listData.value.length = 0;
  dataInterface({}, 'api/graph/1288').then((res) => {
    loading.value = false;
    if (res.data.code === 200) {
      res.data.data.forEach((item) => {
        if (+item.is_read !== 1) {
          list1.push(item);
        } else {
          list2.push(item);
        }
      });
      list1.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));
      list2.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));
      // list=[...list1]
      listData.value = [...list1, ...list2];
      if (listData.value.length && keyId.value === -1) {
        keyId.value = listData.value[0].id;
      }
      listLength.value = listData.value.length;
      emits('giveLength', listData.value.length);
      searchList.value = listData.value;
      emits('isLoading', false);

      res.data.data.forEach((item) => {
        if (+item.is_read === 0) {
          noReadList.value++;
        }
      });
      emits('getNoReadList', noReadList.value);
      nextTick(() => {
        scrollTopValue.value = scrollList.value?.scrollHeight;
      });
    }
  });
};

onMounted(() => {
  getData();
});

watch(
  () => props.listIsUpdate,
  (New, Old) => {
    let count = 0;
    const list1 = [];
    const list2 = [];
    dataInterface({}, 'api/graph/1288').then((res) => {
      if (res.data.code === 200) {
        res.data.data.forEach((item) => {
          if (+item.is_read !== 1) {
            list1.push(item);
          } else {
            list2.push(item);
          }
          if (+item.is_read === 0) {
            count++;
          }
        });
        list1.sort(
          (a, b) => Date.parse(b.created_at) - Date.parse(a.created_at)
        );
        list2.sort(
          (a, b) => Date.parse(b.created_at) - Date.parse(a.created_at)
        );
        // list=[...list1]
        listData.value = [...list1, ...list2];
        listData.value
          .slice((page.value - 1) * 20, page.value * 20)
          .forEach((item, index) => {
            if (item.id === keyId.value) {
              nextTick(() => {
                scrollList.value.scrollTop =
                  index *
                  (scrollTopValue.value /
                    listData.value.slice((page.value - 1) * 20, page.value * 20)
                      .length);
              });
            }
          });
        listLength.value = listData.value.length;
        searchList.value = listData.value;
        emits('giveLength', listData.value.length);
        noReadList.value = count;
        emits('getNoReadList', noReadList.value, true);

        Message({
          message: i18n.t('components.popContentList.202539-7'),
          type: 'success',
        });
      }
    });
  }
);

const props = defineProps({
  listIsUpdate: {
    type: Boolean,
    default: false,
  },
  inputCtx: {
    type: String,
    default: '',
  },
  getNoReadList: {
    type: Function,
    default: () => {},
  },
  loading: {
    type: Boolean,
    default: true,
  },
});
watch(
  () => props.inputCtx,
  (New, Old) => {
    let list1 = [];
    let list2 = [];
    if (New === '') {
      page.value = 1;
    } else {
      page.value = -1;
    }
    searchList.value.forEach((item) => {
      if (item.warn_content.includes(New)) {
        if (+item.is_read !== 1) {
          list1.push(item);
        } else {
          list2.push(item);
        }
      }
    });
    list1.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));
    list2.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));
    listData.value = [...list1, ...list2];
    listLength.value = listData.value.length;
    emits('giveLength', listData.value.length);
  }
);

const emits = defineEmits([
  'getItemData',
  'isLoading',
  'getNoReadList',
  'giveLength',
]);
const pushDetail = (item, i) => {
  emits('getItemData', item);
  keyId.value = item.id;
};

defineExpose({
  getData,
});
</script>

<style lang="less" scoped>
.notify-pop-left-list {
  display: flex;
  margin-bottom: 14px;
  overflow-x: hidden;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: var(--Radio-16, 16px) 0px 0px 0px;
  border-right: 1px solid var(--border-on-surface-weak, #f0f1f4);
  background: var(--overall-surface-default, #fff);
  ::v-deep(.el-pagination) {
    position: absolute;
    bottom: 48px;
    width: 100%;
    display: flex;
    justify-content: center;
    // background-color: white;
  }
  .notify-main-list {
    ul {
      margin: 0px;
      padding: 0px;
      display: flex;
      padding: 0px 16px;
      flex-direction: column;
      align-items: flex-start;

      flex: 1 0 0;
      align-self: stretch;

      .li {
        background: var(--overall-surface-default-hover, #f4f6f9);
        display: flex;
        padding: 14px 16px;
        flex: 1 0 0;
        align-self: stretch;
        border-radius: var(--Radio-8, 8px);
        width: 251px;
      }

      li:hover {
        background: var(--overall-surface-default-hover, #f4f6f9);
      }

      li {
        width: 251px;
        display: flex;
        padding: 14px 16px;
        flex: 1 0 0;
        align-self: stretch;
        border-radius: var(--Radio-8, 8px);
        position: relative;

        .item-type {
          display: flex;
          padding: var(--spacing-1, 1px);
          justify-content: center;
          align-items: center;

          img {
            display: flex;
            width: var(--button-list-field-medium, 32px);
            height: var(--button-list-field-medium, 32px);
            padding: var(--spacing-none, 0px) 1px 1px 1px;
            flex-direction: column;
            align-items: center;
            border-radius: 2px;
          }
        }

        .item-main {
          display: flex;
          height: var(--button-list-field-large, 40px);
          flex-direction: column;
          align-items: flex-start;
          flex: 1 0 0;

          .item-main-title {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            width: 215px;

            .paddingTitle {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              flex: 1 0 0;
              overflow: hidden;
              color: var(--text-on-surface-primary, #181b22);
              text-overflow: ellipsis;
              font-family: 'MiSans VF';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              /* 157.143% */
              padding-left: 8px;
            }

            .title {
              padding-left: 8px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              flex: 1 0 0;
              overflow: hidden;
              color: var(--text-on-surface-primary, #181b22);
              text-overflow: ellipsis;
              font-family: 'MiSans VF';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              /* 157.143% */
            }

            img {
              display: flex;
              width: 22px;
              height: 22px;
              justify-content: center;
              align-items: center;
              gap: 8px;
            }
          }

          .item-main-other {
            display: flex;
            padding-left: 8px;
            align-items: center;
            gap: var(--spacing-12, 24px);
            align-self: stretch;

            .item-main-other-content {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              flex: 1 0 0;
              overflow: hidden;
              color: var(--text-on-surface-tertiary, #707786);
              text-overflow: ellipsis;
              font-family: 'MiSans VF';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
              /* 150% */
            }

            .item-main-other-time {
              color: var(--text-on-surface-placeholder, #a4acbd);
              text-align: right;
              font-family: 'MiSans VF';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
              /* 150% */
            }
          }
        }
      }
    }
  }
}
</style>
