<template>
  <div class="alert-detail-pop">
    <div class="alert-detail" :class="{ zoomPop: isZoom }">
      <div class="alert-title">
        <span>{{ $t("alertNotify.alertDetailPop.951902-0") }}</span>
        <div @click="zoomPopFun()">
          <i
            class="iconfont fullscreen"
            :class="!isZoom ? 'iconquanping6' : 'icontuichuquanping1'"
          ></i>
        </div>
        <div>
          <img
            @click="closePop()"
            src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/8acf98a07bb16692fe62692859d76e2a.png"
          />
        </div>
      </div>
      <div class="alert-content">
        <popContentMain
          class="popContentMain"
          :itemData="props.item"
          disabled
        ></popContentMain>
        <PopContentSider
          :siderPlush="siderPlush"
          :itemData="props.item"
          @on-handle="tochange"
          @toAlertItemDataIsUpdate="toAlertItemDataIsUpdate"
        />
      </div>
    </div>
    <PageParse
      v-if="pageUUID"
      :dialog-visible="dialogPageVisible"
      @close="dialogPageVisible = false"
      :pageUUID="pageUUID"
      width="80%"
    />
  </div>
</template>

<script setup>
/* eslint-disable */
import PopContentSider from "../../popContentSider.vue";
import popContentMain from "../../popContentMain.vue";
import PageParse from "@/views/todo/components/rightCont/PagePop.vue";
import { ref, defineProps } from "vue";
import { dataInterface } from "@/apis/data";
import eventBus from "@/plugins/eventBus";
import { Message } from "element-ui";
import i18n from "@/locale/index";
const emits = defineEmits(["close-pop"]);

const dialogPageVisible = ref(false);
const pageUUID = ref("");
const isZoom = ref(false);
const siderPlush = ref(false);

const toAlertItemDataIsUpdate = () => {
  Message({
    message: i18n.t("alertNotify.alertDetailPop.951902-1"),
    type: "success",
  });
};

const props = defineProps({
  item: {
    type: Object,
    default: () => {},
  },
  showDeal: {
    type: Boolean,
    default: false,
  },
});

const tochange = () => {
  if (+props.item.render_method === 1) {
    //渲染页面
    if (props.item.bind_page_form) {
      pageUUID.value = props.item.bind_page_form;
      dialogPageVisible.value = true;
      changeStatus();
    }
  } else {
    //表单处理
    let data = {
      event: "showDetailModal",
      form_uuid: props.item?.bind_page_form || "",
      data_id: +props.item.render_method === 2 ? "" : props.item.deal_data_id,
      object_name: i18n.t("alertNotify.alertDetailPop.951902-2"),
      archi_type: props.item.archi_type,
      disabled: [4].includes(+props.item.render_method),
      show_btn: [2, 3].includes(+props.item.render_method),
      deal_object_uuid: props.item.deal_object_uuid,
    };
    window.parent.postMessage(data, "*");
  }
};
// 开关弹窗
const closePop = () => {
  emits("close-pop");
};

const zoomPopFun = () => {
  isZoom.value = !isZoom.value;
};

const changeStatus = () => {
  dataInterface({
    object_uuid: "object635201835e212",
    need_deal: "3",
    __method_name__: "updateData",
    data_id: props.item.id,
  }).then((res) => {
    if (res.data.code === 200) {
      siderPlush.value = !siderPlush.value;
    }
  });
};

eventBus.$on("handle-todo-warn-status", () => {
  changeStatus();
});
</script>

<style lang="less" scoped>
.alert-detail-pop {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 10;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  // overflow: hidden;
  .zoomPop {
    display: flex;
    justify-content: center;
    width: 100% !important;
    height: 100% !important;
  }

  .alert-detail {
    display: flex;
    width: 960px;
    // // height: 720px;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    border-radius: var(--Radio-16, 16px);
    background: var(--overall-surface-default, #fff);
    overflow: hidden;
    position: relative;

    .alert-title {
      display: flex;
      height: 56px;
      padding: 0px var(--spacing-10, 20px) 0px var(--spacing-12, 24px);
      align-items: center;
      gap: 12px;

      flex-shrink: 0;
      align-self: stretch;
      border-bottom: 1px solid var(--border-on-surface-weak, #f0f1f4);

      span {
        color: var(--text-on-surface-primary, #181b22);
        font-family: "MiSans VF";
        font-size: 16px;
        font-style: normal;
        font-weight: 380;
        line-height: 24px;
        /* 150% */
        flex: 1 0 0;
      }

      div {
        border-radius: 5px;

        img {
          display: flex;
          align-items: flex-start;
          border-radius: var(--radius-2, 4px);
          width: 32px;
        }
      }

      .fullscreen {
        font-size: 22px;
        color: #4d535e;
      }

      // div:hover {
      //     background-color: #F4F6F9;
      // }

      // div:active {
      //     background-color: #b8becce3;
      // }
    }

    .alert-content {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex: 1 0 0;
      align-self: stretch;
      position: relative;

      .right-sider {
        display: flex;
        width: 200px;
        height: 664px;
        padding: 48px var(--spacing-16, 32px) 48px 0px;
        flex-direction: column;
        align-items: center;
        gap: var(--spacing-16, 32px);
        position: absolute;
        box-sizing: border-box;
        right: 0px;
      }
    }

    .activeContent {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex: 1 0 0;
      align-self: stretch;
      position: relative;
    }
  }
}
</style>
