<!--
 * @Author: qinmengyuan
 * @Date: 2024-07-23 14:03:34
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @LastEditTime: 2024-08-27 15:26:54
 * @FilePath: \dataview-viewer-test\src\views\todo\components\leftContent\FilterModal.vue
-->
<template>
  <div>
    <Form ref="formRef" v-model="form" label-position="top">
      <FormItem
        :label="$t('leftContent.FilterModal.857271-0')"
        prop="flow_approval_number"
      >
        <el-input v-model="form.flow_approval_number" />
      </FormItem>
      <FormItem
        :label="$t('leftContent.FilterModal.857271-1')"
        prop="data_archi_name"
      >
        <el-input v-model="form.data_archi_name" />
      </FormItem>
      <FormItem
        :label="$t('leftContent.FilterModal.857271-2')"
        prop="object_name"
      >
        <el-input v-model="form.object_name" />
      </FormItem>
      <FormItem :label="$t('leftContent.FilterModal.857271-3')" prop="title">
        <el-input v-model="form.title" />
      </FormItem>
      <FormItem
        :label="$t('leftContent.FilterModal.857271-4')"
        prop="user_name"
      >
        <el-input v-model="form.user_name" />
      </FormItem>
      <FormItem :label="$t('leftContent.FilterModal.857271-5')" prop="status">
        <el-select v-model="form.status" clearable>
          <el-option
            v-for="item in STATE_LIST"
            :label="item.label"
            :value="item.value"
            :key="item.value"
          ></el-option>
        </el-select>
      </FormItem>
      <FormItem
        v-if="type === 'list'"
        :label="$t('leftContent.FilterModal.857271-6')"
        prop="start_date"
      >
        <el-date-picker
          v-model="form.start_date"
          type="datetime"
          :placeholder="$t('leftContent.FilterModal.857271-7')"
          value-format="yyyy-MM-dd hh:mm:ss"
        >
        </el-date-picker>
      </FormItem>
      <FormItem
        v-if="type === 'list'"
        :label="$t('leftContent.FilterModal.857271-8')"
        prop="end_date"
      >
        <el-date-picker
          v-model="form.end_date"
          type="datetime"
          :placeholder="$t('leftContent.FilterModal.857271-9')"
          value-format="yyyy-MM-dd hh:mm:ss"
        >
        </el-date-picker>
      </FormItem>
    </Form>
    <div>
      <el-button type="primary" @click="handleOk" class="btn">{{
        $t("leftContent.FilterModal.857271-10")
      }}</el-button>
      <el-button type="info" plain @click="handleReset" class="btn">{{
        $t("leftContent.FilterModal.857271-11")
      }}</el-button>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, defineProps, reactive, computed } from "vue";
import { Form, FormItem } from "element-ui";
import i18n from "@/locale/index";
const props = defineProps({
  //日周报
  type: {
    type: String,
    default: "list",
  },
});
const emits = defineEmits(["ok"]);
const formRef = ref();
const form = reactive({
  flow_approval_number: "",
  data_archi_name: "",
  object_name: "",
  title: "",
  user_name: "",
  status: "",
  start_date: "",
  end_date: "",
});
const handleOk = () => {
  emits("ok", form);
};
const STATE_LIST = computed(() => [
  { value: -1, label: i18n.t("leftContent.FilterModal.857271-12") },
  { value: 0, label: i18n.t("leftContent.FilterModal.857271-13") },
  { value: 1, label: i18n.t("leftContent.FilterModal.857271-14") },
  { value: 5, label: i18n.t("leftContent.FilterModal.857271-15") },
  { value: 6, label: i18n.t("leftContent.FilterModal.857271-16") },
  { value: 7, label: i18n.t("leftContent.FilterModal.857271-17") },
]);
const handleReset = () => {
  form.data_archi_name = "";
  form.end_date = "";
  form.flow_approval_number = "";
  form.object_name = "";
  form.start_date = "";
  form.status = "";
  form.title = "";
  form.user_name = "";
  emits("ok", form);
};
</script>
<style lang="less" scoped>
.el-form {
  width: 280px;
  height: 360px;
  overflow-y: auto;
  padding-right: 8px;
  .el-form-item {
    margin-bottom: 16px;
    ::v-deep(.el-form-item__label) {
      line-height: 24px !important;
      padding: 0 !important;
      font-size: 12px;
      color: #424751;
    }
    .el-date-editor,
    .el-select {
      width: 100%;
    }
    ::v-deep {
      .el-input__inner {
        border-radius: 6px;
        &:hover {
          background: #fff;
          border: 1px solid #dcdfe6;
        }
        &:focus {
          border: 1px solid var(--themeColor);
          background: #fff;
          box-shadow: 0px 0px 0px 0px var(--themeColor) inset,
            0px 0px 0px 2px rgba(var(--themeColorRgb), 0.2);
        }
      }
    }
  }
}
.btn {
  border-radius: 6px;
  padding: 9px 24px;
}
</style>
