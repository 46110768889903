var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_setup.props.itemType === 1 && _setup.props.itemData)?_c('div',{staticClass:"right-sider"},[_c('div',[(_setup.is1Read === 0)?_c('div',{staticClass:"deal-content",on:{"click":function($event){return _setup.switchReadStatus()}}},[_vm._m(0),_c('span',[_vm._v(_vm._s(_vm.$t('components.popContentSider.441255-0')))])]):(_setup.is1Read === 1)?_c('div',{staticClass:"deal-content"},[_vm._m(1),_c('span',[_vm._v(_vm._s(_vm.$t('components.popContentSider.441255-1')))])]):_vm._e()]),_c('div',[(_setup.is1Deal === 2)?_c('div',{staticClass:"deal-content",on:{"click":function($event){return _setup.toDeal()}}},[_vm._m(2),_c('span',[_vm._v(_vm._s(_vm.$t('components.popContentSider.441255-2')))])]):(_setup.is1Deal === 3)?_c('div',{staticClass:"deal-content"},[_vm._m(3),_c('span',[_vm._v(_vm._s(_vm.$t('components.popContentSider.441255-3')))])]):_vm._e()])]):_c('div',{staticClass:"right-sider"},[_c('div',[(_setup.is2Read === 0)?_c('div',{staticClass:"deal-content",on:{"click":function($event){return _setup.switchReadStatus()}}},[_vm._m(4),_c('span',[_vm._v(_vm._s(_vm.$t('components.popContentSider.441255-0')))])]):(_setup.is2Read === 1)?_c('div',{staticClass:"deal-content"},[_vm._m(5),_c('span',[_vm._v(_vm._s(_vm.$t('components.popContentSider.441255-1')))])]):_vm._e()]),_c('div',[(_setup.is2Deal === 2)?_c('div',{staticClass:"deal-content",on:{"click":function($event){return _setup.toRep()}}},[_vm._m(6),_c('span',[_vm._v(_vm._s(_vm.$t('components.popContentSider.441255-4')))])]):(_setup.is2Deal === 3)?_c('div',{staticClass:"deal-content"},[_vm._m(7),_c('span',[_vm._v(_vm._s(_vm.$t('components.popContentSider.441255-5')))])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"noDeal"},[_c('i',{staticClass:"iconfont iconyueduzhuangtai"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"isDeal"},[_c('i',{staticClass:"iconfont iconyueduzhuangtai",staticStyle:{"color":"#387ffc"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"noDeal"},[_c('i',{staticClass:"iconfont icondianjichuli"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"isDeal"},[_c('i',{staticClass:"iconfont icondianjichuli",staticStyle:{"color":"#387ffc"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"noDeal"},[_c('i',{staticClass:"iconfont iconyueduzhuangtai"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"isDeal"},[_c('i',{staticClass:"iconfont iconyueduzhuangtai",staticStyle:{"color":"#387ffc"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"noDeal"},[_c('i',{staticClass:"iconfont icondianjichuli"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"isDeal"},[_c('i',{staticClass:"iconfont icondianjichuli",staticStyle:{"color":"#387ffc"}})])
}]

export { render, staticRenderFns }