var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(_setup.Dialog,{staticClass:"finish-modal",attrs:{"visible":_vm.dialogVisible,"before-close":_setup.handleClose,"append-to-body":true,"fullscreen":_setup.fullscreen,"title":_vm.$t('leftContent.FlowFinishPop.107297-0', {
      name: _vm.detail.user_name,
      object: _vm.detail.object_name,
    })},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"container"},[_c('i',{class:{
        iconfont: true,
        iconquanping6: !_setup.fullscreen,
        icontuichuquanping1: _setup.fullscreen,
      },on:{"click":function($event){_setup.fullscreen = !_setup.fullscreen}}}),_c('img',{attrs:{"src":"https://openim-1309784708.cos.ap-shanghai.myqcloud.com/78a9790229319b06b6fef4118517bf4f.png","alt":""}}),_c('div',{staticClass:"success"},[_vm._v(_vm._s(_vm.$t("leftContent.FlowFinishPop.107297-2")))]),_c('div',{staticClass:"desc"},[_vm._v(" "+_vm._s(_setup.second)+_vm._s(_vm.$t("leftContent.FlowFinishPop.107297-3"))+" ")]),_c('div',{staticClass:"btn-cont"},[_c('el-button',{staticClass:"btn",attrs:{"type":"info","plain":""},on:{"click":function($event){return _setup.handleClose()}}},[_vm._v(_vm._s(_vm.$t("leftContent.FlowFinishPop.107297-4")))]),_c('el-button',{staticClass:"btn",attrs:{"plain":""},on:{"click":_setup.handleNext}},[_vm._v(_vm._s(_vm.$t("leftContent.FlowFinishPop.107297-5")))])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }