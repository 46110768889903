<!--
 * @Author: 冉桂精 156189868@qq.com
 * @Date: 2024-07-22 09:09:56
 * @LastEditors: qinmengyuan 2715025514@qq.com
 * @LastEditTime: 2024-09-02 17:36:58
 * @FilePath: \dataview-viewer-test\src\views\todo\components\calendar\HoverRow.vue
 * @Description: 
-->
<template>
  <div id="tooltip-cont" v-if="detail" :style="position">
    <div class="top-cont">
      <div class="task-status">
        <div
          class="inner-btn"
          :style="{
            background: STATE_LIST.find(
              (item) => item.value === +detail?.status
            )?.color,
          }"
        >
          {{ STATE_LIST.find((item) => item.value === +detail?.status)?.label }}
        </div>
      </div>
      <p>{{ i18n.locale === 'en' ? detail?.t_title : detail?.title }}</p>
    </div>
    <ul>
      <li v-for="(item, index) in showList" :key="index">
        <i class="iconfont iconredesign" :class="item.icon"></i>
        <img
          class="user-avatar"
          v-if="item.label === 'user_name' && detail.user_avatar"
          :src="detail?.user_avatar"
          alt=""
        />
        <p>{{ detail[item.label] || '-' }}</p>
        <p class="overdue-status" v-if="item.label === 'endTime'">
          {{ deadLine(detail) }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script setup>
/* eslint-disable */
import { defineProps, ref, computed } from 'vue';
import { STATE_LIST } from '@/views/todo/constant.js';
import i18n from '@/locale/index';
const emits = defineEmits(['change-line-date']);

const props = defineProps({
  detail: {
    type: Object,
    default: null,
  },
  position: {
    type: Object,
    default: {
      left: 0,
    },
  },
});
const showList = computed(() => [
  { icon: 'icona-Outlined5', label: 'flow_approval_number' },
  {
    icon: 'iconjiagou11',
    label: i18n.locale === 'en' ? 't_archi_name' : 'archi_name',
  },
  {
    icon: 'icona-yingyong3',
    label: i18n.locale === 'en' ? 't_object_name' : 'object_name',
  },
  { icon: 'iconform-shijian', label: 'endTime' },
  // {icon:'icon-redesign-xiaoxikuang',label:'archi_name'},
  { icon: 'icona-liuchengliuzhuan', label: 'user_name' },
]);
// 时间格式化
const deadLine = (item) => {
  const deadLine = item.endDate;
  const nowTime = new Date();
  let date1_s = new Date(deadLine?.replace(/-/g, '/'));
  let dateDiff = nowTime.getTime() - date1_s.getTime();
  let date = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数

  if (nowTime.getTime() > date1_s.getTime() && date) {
    return i18n.locale === 'en'
      ? date + ' ' + 'days overdue'
      : '逾期' + date + '天';
  }
  return '';
};
</script>

<style lang="less" scoped>
#tooltip-cont {
  width: 240px;
  position: fixed;
  left: 0;
  z-index: 2001;
  padding: 16px 20px 20px 20px;
  border-radius: var(--Radio-8, 8px);
  background: var(--overall-surface-default, #fff);
  pointer-events: none;
  /* shadow/on-surface/medium */
  box-shadow: 0px 1px 3px 0px rgba(25, 27, 35, 0.04),
    0px 0px 0px 0px rgba(255, 255, 255, 0.01) inset,
    0px 2px 16px 0px rgba(25, 27, 35, 0.08);
  .top-cont {
    border-bottom: 1px solid #f0f1f4;
    padding-bottom: 14px;
    p {
      flex: 1;
      color: var(--text-on-surface-primary, #181b22);
      font-family: 'MiSans VF';
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
    .task-status {
      margin-left: 12px;
      float: right;
      .inner-btn {
        font-size: 14px;
        color: #585f6c;
        line-height: 20px;
        border-radius: 6px;
        padding: 4px 12px;
      }
    }
  }
  ul {
    margin-top: 4px;
    li {
      margin-top: 12px;
      color: var(--text-on-surface-primary, #181b22);
      font-family: 'MiSans VF';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      display: flex;
      flex-direction: row;
      i {
        margin-right: 16px;
        font-size: 18px;
        color: #8991a2;
        line-height: 20px;
      }
      .overdue-status {
        color: #ed474a;
        margin-left: 16px;
        font-size: 14px;
      }
      .user-avatar {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 6px;
      }
    }
  }
}
</style>
