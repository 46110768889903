<template>
  <div class="alert-notify-pop">
    <div class="alert-notify" :class="{ zoomPop: isZoom }">
      <div class="alert-notify-title">
        <span>{{ $t('alertNotify.alertNotifyPop.295431-0') }}</span>
        <div @click="zoomPopFun()">
          <i
            class="iconfont fullscreen"
            :class="!isZoom ? 'iconquanping6' : 'icontuichuquanping1'"
          ></i>
        </div>
        <div>
          <img
            @click="closeAlertPop()"
            src="https://openim-1309784708.cos.ap-shanghai.myqcloud.com/8acf98a07bb16692fe62692859d76e2a.png"
          />
        </div>
      </div>
      <div class="alert-notify-content" v-loading="loading">
        <div class="alert-notify-content-list" v-loading="listLoading">
          <div class="notify-top" v-if="!loading">
            <el-input
              :placeholder="$t('alertNotify.alertNotifyPop.295431-1')"
              v-model="inputCtx"
              @input="searchItem"
              style="text-align: center; outline: 1px solid transparent"
            >
            </el-input>
            <svg
              v-if="inputCtx === ''"
              style="position: absolute; top: 25px; left: 120px"
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.7347 13.5277L11.7417 10.5346C13.6266 8.27166 13.3982 4.92638 11.2232 2.94059C9.04825 0.954801 5.69605 1.03098 3.61351 3.11351C1.53098 5.19605 1.4548 8.54825 3.44059 10.7232C5.42638 12.8982 8.77166 13.1266 11.0346 11.2417L14.0277 14.2347C14.2229 14.43 14.5395 14.43 14.7347 14.2347C14.93 14.0395 14.93 13.7229 14.7347 13.5277ZM3.0177 7.0177C3.0177 4.53242 5.03242 2.5177 7.5177 2.5177C10.003 2.5177 12.0177 4.53242 12.0177 7.0177C12.0177 9.50298 10.003 11.5177 7.5177 11.5177C5.03356 11.5149 3.02046 9.50184 3.0177 7.0177Z"
                fill="#8991A2"
              />
            </svg>
          </div>
          <popContentList
            ref="leftListRef"
            @getNoReadList="getNoReadList"
            class="middle-cont"
            @getItemData="getItemData"
            :listIsUpdate="listIsUpdate"
            @isLoading="isLoading"
            :inputCtx="inputCtx"
            :loading="loading"
            @giveLength="giveLength"
          >
          </popContentList>

          <div
            class="alert-notify-list-bottom"
            @click="allRead"
            :plain="true"
            v-if="!loading"
          >
            <div class="bottom-box">
              <i class="iconquanbuyidu iconfont"></i>
              <span :class="noReadList ? 'read-dot' : ''">{{
                $t('alertNotify.alertNotifyPop.295431-2')
              }}</span>
            </div>
          </div>
        </div>
        <div class="alert-notify-content-main" v-if="!loading">
          <popContentMain :itemData="itemData" disabled></popContentMain>
          <PopContentSider
            class="popContentSider"
            :itemData="itemData"
            :switchType="2"
            @toAlertItemDataIsUpdate="toAlertItemDataIsUpdate"
            @on-handle="tochange"
            :isZoom="isZoom"
            :siderPlush="siderPlush"
          />
        </div>
      </div>
    </div>
    <PageParse
      v-if="pageUUID"
      :dialog-visible="dialogPageVisible"
      @close="dialogPageVisible = false"
      :pageUUID="pageUUID"
      width="80%"
    />
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, defineEmits, defineProps, inject, onMounted } from 'vue';
import popContentList from '../../popContentList.vue';
import popContentMain from '../../popContentMain.vue';
import PopContentSider from '../../popContentSider.vue';
import { dataInterface } from '@/apis/data';
import eventBus from '@/plugins/eventBus';
import { Message } from 'element-ui';
import PageParse from '@/views/todo/components/rightCont/PagePop.vue';
import i18n from '@/locale/index';

const dialogPageVisible = ref(false);
const pageUUID = ref('');
const noReadList = ref(0);
const listLoading = ref(false);
const listLength = ref(0);
const listIsUpdate = ref(false);
const leftListRef = ref(null);
const itemData = ref({});
const inputCtx = ref('');
const loading = ref(true);
const isZoom = ref(false);
const siderPlush = ref(false);

const emits = defineEmits(['close-pop']);

const toUpdate = inject(['toUpdate']);

const tochange = () => {
  if (+itemData.value.render_method === 1) {
    //渲染页面
    if (itemData.value.bind_page_form) {
      pageUUID.value = itemData.value.bind_page_form;
      dialogPageVisible.value = true;
      changeStatus();
    }
  } else {
    //表单处理
    let data = {
      event: 'showDetailModal',
      form_uuid: itemData.value?.bind_page_form || '',
      data_id:
        +itemData.value.render_method === 2 ? '' : itemData.value.deal_data_id,
      object_name: i18n.t('alertNotify.alertNotifyPop.295431-3'),
      archi_type: itemData.value.archi_type,
      disabled: [4].includes(+itemData.value.render_method),
      show_btn: [2, 3].includes(+itemData.value.render_method),
      deal_object_uuid: itemData.value.deal_object_uuid,
    };
    window.parent.postMessage(data, '*');
  }
};

const props = defineProps({
  alertItemData: {
    type: [],
    default: () => [],
  },
});

const closeAlertPop = () => {
  emits('close-pop');
};

const giveLength = (payload) => {
  listLength.value = payload;
};

const allRead = () => {
  let list = [];
  let list2 = [];
  const id = JSON.parse(localStorage.getItem('userInfo')).id;
  //
  if (noReadList.value) {
    listLoading.value = true;
    props.alertItemData.forEach((item) => {
      if (+item.is_read === 0) {
        list.push({
          id: item.id,
          value: 1,
        });
        list2.push({
          warn_information_list_id: item.id,
          alert_people: id,
        });
      }
    });
    dataInterface({
      __method_name__: 'batchUpdate',
      object_uuid: 'object635201835e212',
      field_code: 'read_type',
      data: list,
    }).then((res) => {
      if (res.data.code === 200) {
        dataInterface({
          __method_name__: 'batchCreate', //该参数值取固定值
          object_uuid: 'object66ab221ff39d8', //该参数值取固定值
          batch_data: list2,
        }).then((res1) => {
          if (res1.data.code === 200) {
            listIsUpdate.value = !listIsUpdate.value;
            toUpdate(true, false);
            noReadList.value = 0;
            listLoading.value = false;
          }
        });
      }
    });
  } else {
    Message({
      message: i18n.t('alertNotify.alertNotifyPop.295431-4'),
      type: 'message',
    });
  }
};

if (props.alertItemData) {
  const list1 = [];
  const list2 = [];
  let list = [];
  props.alertItemData.forEach((item) => {
    if (+item.is_read !== 1) {
      list1.push(item);
    } else {
      list2.push(item);
    }
  });
  list1.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));
  list2.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at));
  list = [...list1, ...list2];
  itemData.value = list[0];
}
const getItemData = (payload) => {
  itemData.value = payload;
};

const searchItem = () => {};

const toAlertItemDataIsUpdate = (payload) => {
  listIsUpdate.value = !listIsUpdate.value;
  listLoading.value = payload;
};

const isLoading = (payload) => {
  loading.value = payload;
};

const zoomPopFun = () => {
  isZoom.value = !isZoom.value;
};

const getNoReadList = (payload, p1) => {
  noReadList.value = payload;
  listLoading.value = false;
  if (p1) {
    siderPlush.value = !siderPlush.value;
  }
};

const changeStatus = () => {
  dataInterface({
    object_uuid: 'object635201835e212',
    need_deal: '3',
    __method_name__: 'updateData',
    data_id: itemData.value.id,
  }).then((res) => {
    if (res.data.code === 200) {
      siderPlush.value = !siderPlush.value;
      leftListRef.value.getData();
    }
  });
};

//已处理传入接口
eventBus.$on('handle-todo-warn-status', () => {
  changeStatus();
});

const getData = () => {
  dataInterface({}, 'api/graph/1288').then((res) => {
    if (res.data.code === 200) {
      res.data.data.forEach((item) => {
        if (+item.is_read === 0) {
          noReadList.value++;
        }
      });
    }
  });
};

onMounted(() => {
  getData();
});
</script>

<style lang="less" scoped>
.alert-notify-pop {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 10;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;

  .zoomPop {
    display: flex;
    width: 100% !important;
    height: 100% !important;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    border-radius: var(--Radio-16, 16px);
    background: var(--overall-surface-default, #fff);
    overflow: hidden;
  }

  .alert-notify {
    display: flex;
    width: 1280px;
    height: 800px;
    flex-direction: column;
    align-items: center;
    flex-shrink: 0;
    border-radius: var(--Radio-16, 16px);
    background: var(--overall-surface-default, #fff);
    overflow: hidden;
    position: relative;
    .alert-notify-title {
      display: flex;
      height: 56px;
      padding: 0px var(--spacing-10, 20px) 0px var(--spacing-12, 24px);
      align-items: center;
      gap: 12px;
      flex-shrink: 0;
      align-self: stretch;
      border-bottom: 1px solid var(--border-on-surface-weak, #f0f1f4);

      span {
        color: var(--text-on-surface-primary, #181b22);
        font-family: 'MiSans VF';
        font-size: 16px;
        font-style: normal;
        font-weight: 380;
        line-height: 24px;
        /* 150% */
        flex: 1 0 0;
      }

      div {
        border-radius: 5px;

        img {
          display: flex;
          align-items: flex-start;
          border-radius: var(--radius-2, 4px);
          width: 32px;
        }
      }
      .fullscreen {
        font-size: 22px;
        color: #4d535e;
      }

      // div:hover {
      //     background-color: #F4F6F9;
      // }

      // div:active {
      //     background-color: #A4ACBD;
      // }
    }

    .alert-notify-content {
      display: flex;
      align-items: flex-start;
      flex: 1 0 0;
      align-self: stretch;
      overflow: hidden;

      .alert-notify-content-list {
        display: flex;
        width: 320px;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
        border-radius: var(--Radio-16, 16px) 0px 0px 0px;
        border-right: 1px solid var(--border-on-surface-weak, #f0f1f4);
        background: var(--overall-surface-default, #fff);
        position: relative;

        .middle-cont {
          flex: 1;
          // overflow-x: hidden;
        }

        .notify-top {
          display: flex;
          padding: 16px 16px 0px 16px;
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          align-self: stretch;

          :deep(.el-input__inner) {
            text-align: center;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            color: var(--text-on-surface-placeholder, #a4acbd);
            text-overflow: ellipsis;

            /* web/cn/body/body-01 */
            font-family: 'PingFang SC';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            /* 157.143% */
            letter-spacing: 0.5px;
          }

          .notify-top-search {
            display: flex;
            height: var(--button-list-field-medium, 32px);
            padding: 0px var(--spacing-none, 0px) 0px 0px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            border-radius: 8px;
            border: 1px solid var(--field-border-default, #f0f1f4);
            background: var(--overall-surface-default, #fff);

            img {
              width: var(--Radio-16, 16px);
              height: var(--Radio-16, 16px);
            }

            span {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
              color: var(--text-on-surface-placeholder, #a4acbd);
              text-overflow: ellipsis;

              /* web/cn/body/body-01 */
              font-family: 'PingFang SC';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              /* 157.143% */
              letter-spacing: 0.5px;
            }
          }
        }

        .alert-notify-list-bottom {
          display: flex;
          height: 48px;
          padding: 0px 24px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          align-self: stretch;
          width: -webkit-fill-available;
          .bottom-box:hover {
            & span {
              color: #387ffc;
            }
            & i {
              color: #387ffc;
            }
          }
          .bottom-box {
            display: flex;
            align-items: center;
            gap: 8px;
            flex: 1 0 0;
            align-self: stretch;
            border-top: 1px solid var(--border-on-surface-weak, #f0f1f4);
            color: #a4acbd;
            i {
              color: #a4acbd;
            }
            span {
              color: var(--text-on-surface-placeholder, #a4acbd);
              font-family: 'MiSans VF';
              font-size: 13px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              position: relative;
            }
            .read-dot::before {
              content: '';
              width: 5px;
              height: 5px;
              border-radius: 50%;
              background: #fc5558;
              position: absolute;
              top: 4px;
              left: -14px;
            }
          }
        }
      }

      .alert-notify-content-main {
        display: flex;
        padding-right: 104px;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
        align-self: stretch;
        box-sizing: border-box;
        position: relative;

        .popContentMain {
          position: absolute;
          left: 0;
          :deep(.noData) {
            height: 100vh;
          }
        }
        .popContentMain1 {
          position: absolute;
          left: 50%;
          transform: translateX(-80%);
          :deep(.noData) {
            height: 100vh;
          }
        }
        .popContentSider {
          display: flex;
          width: 200px;
          height: 744px;
          padding: 48px var(--spacing-16, 32px) 48px 0px;
          flex-direction: column;
          align-items: center;
          gap: var(--spacing-16, 32px);
          position: absolute;
          right: 0px;
          // padding-left: 100px;
          // box-sizing: content-box;
        }
        .activeDispose {
          display: flex;
          background-color: white;
          width: 400px;
          height: 100%;
          flex-direction: column;
          align-items: flex-start;
          position: absolute;
          right: 0px !important;
          top: -1px;
          border-left: 1px solid var(--border-on-surface-weak, #f0f1f4);
          border-top: 1px solid var(--border-on-surface-weak, #f0f1f4);
          box-sizing: border-box;
        }
        .alert-dispose {
          display: flex;
          background-color: white;
          width: 400px;
          height: 100%;
          flex-direction: column;
          align-items: flex-start;
          position: absolute;
          right: -400px;
          top: -1px;
          border-left: 1px solid var(--border-on-surface-weak, #f0f1f4);
          border-top: 1px solid var(--border-on-surface-weak, #f0f1f4);
          box-sizing: border-box;
        }
        .dispose-title {
          display: flex;
          height: 56px;
          padding: 0px var(--spacing-10, 20px) 0px var(--spacing-12, 24px);
          align-items: center;
          gap: 8px;
          flex-shrink: 0;
          align-self: stretch;

          span {
            color: var(--text-on-surface-primary, #181b22);
            font-family: 'MiSans VF';
            font-size: 16px;
            font-style: normal;
            font-weight: 380;
            line-height: 24px;
            /* 150% */
            flex: 1 0 0;
          }

          img {
            display: flex;
            width: var(--button-list-field-medium, 32px);
            height: var(--button-list-field-medium, 32px);
            justify-content: center;
            align-items: center;
          }
        }

        .dispose-main {
          width: 100%;
          // max-height: 543px;
          height: 100%;
          overflow: hidden;
          box-sizing: border-box;
          display: flex;
          padding: 0px var(--spacing-12, 24px);
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          flex: 1 0 0;
          align-self: stretch;
        }

        .dispose-bottom {
          display: flex;
          padding: 16px var(--spacing-10, 20px);
          justify-content: flex-end;
          align-items: center;
          gap: var(--spacing-6, 12px);
          align-self: stretch;

          .bnt-cancel {
            display: flex;
            height: 32px;
            padding: 0px var(--spacing-16, 32px);
            justify-content: center;
            align-items: center;
            gap: var(--spacing-3, 6px);
            border-radius: var(--radius-3, 6px);
            background: var(--_button-neutral-tertiary-background, #f0f1f4);
          }

          .bnt-cancel:hover {
            background-color: #dfe6ec;
          }

          .bnt-cancel:active {
            background-color: #b8becce3;
          }

          .bnt-confirm {
            display: flex;
            height: 32px;
            padding: 0px var(--spacing-16, 32px);
            justify-content: center;
            align-items: center;
            gap: var(--spacing-3, 6px);
            border-radius: var(--radius-3, 6px);
            background: var(--_button-brand-primary-background, #387ffc);
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
